import React from 'react';
import { graphql } from 'gatsby';
import NMDSILayout from '../../components/nmdsi/NMDSILayout';
import Header from '../../components/nmdsi/Header';
import About from '../../components/nmdsi/About';
import Space from '../../components/nmdsi/Space';
import Contact from '../../components/Shared/Contact';

import MoreStories from '../../components/Shared/MoreStories';
import Event from '../../components/nmdsi/Event';

const IndexPage = ({ data }) => {
  return (
    <NMDSILayout>
      <div className="mx-auto max-w-screen-2xl">
        <Header />
        <MoreStories data={data} />
        <Event data={data} />
        <About />
        <Space />
      </div>
      <Contact isNMDSI={true} />
    </NMDSILayout>
  );
};

export default IndexPage;
export const postQuery = graphql`
  query {
    allStrapiPosts(
      sort: { order: DESC, fields: published_at }
      limit: 3
      filter: { websites: { elemMatch: { name: { eq: "NMDSI" } } } }
    ) {
      edges {
        node {
          slug
          published_at
          image {
            url
          }
          title
          categories {
            name
          }
          coverImage {
            url
          }
          websites {
            name
            id
          }
        }
      }
    }
    allStrapiEvents(sort: { order: DESC, fields: published_at }) {
      edges {
        node {
          title
          content
          image {
            url
          }
          start
          end
          location
          registrationUrl
        }
      }
    }
    latestEvents: allStrapiEvents(sort: { order: DESC, fields: published_at }, limit: 2) {
      edges {
        node {
          title
          content
          image {
            url
          }
          start
          end
          location
          registrationUrl
        }
      }
    }
    data: allStrapiPosts(
      sort: { order: DESC, fields: published_at }
      limit: 1
      filter: { categories: { elemMatch: { name: { eq: "Data for Good" } } } }
    ) {
      edges {
        node {
          slug
          published_at
          title
          categories {
            name
          }
          image {
            url
          }
        }
      }
    }
    people: allStrapiPosts(
      sort: { order: DESC, fields: published_at }
      limit: 1
      filter: { categories: { elemMatch: { name: { eq: "People Profiles" } } } }
    ) {
      edges {
        node {
          slug
          published_at
          title
          categories {
            name
          }
          image {
            url
          }
        }
      }
    }
    happening: allStrapiPosts(
      sort: { order: DESC, fields: published_at }
      limit: 1
      filter: { categories: { elemMatch: { name: { eq: "What's Happening" } } } }
    ) {
      edges {
        node {
          slug
          published_at
          title
          categories {
            name
          }
          image {
            url
          }
        }
      }
    }
    insights: allStrapiPosts(
      sort: { order: DESC, fields: published_at }
      limit: 1
      filter: { categories: { elemMatch: { name: { eq: "Insights" } } } }
    ) {
      edges {
        node {
          slug
          published_at
          title
          categories {
            name
          }
          image {
            url
          }
        }
      }
    }
    allStrapiCategories(filter: { websites: { elemMatch: { name: { eq: "NMDSI" } } } }, limit: 4) {
      nodes {
        name
        posts {
          slug
          published_at
          title
          excerpt
          coverImage {
            url
          }
        }
      }
    }
  }
`;
